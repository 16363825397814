<template>
<div class="components">
    <div id="Content">
        <div class="shadow"></div>
        <!-- Start of content-inner --> 
        <div class="content-inner">
            <section>
                <div>
                    <p><a href="src\views\MyEvents.vue">My Events</a> > Automated Email</p>
                </div>
            </section>
            <section>
                <p v-if="errorsToCorrect.length">
                    <b>Please correct the following error(s):</b>
                    <ul>
                        <li v-for="error in errorsToCorrect" :key="error.name">{{ error }}</li>
                    </ul>
                </p>
            </section>
            <div class="divider"></div>                        
            <section class="row">
                <!-- Start of Connect Mail column --> 
                <div class="cust-col quarter">
                    <h2>Connect Your Mail</h2>
                    <diyobo-input 
                        label="From Name" 
                        type="text" 
                        v-model="nameInput"
                        :error="nameError"
                        :val="nameInput"
                        placeholder="Name in buyer's inbox" 
                        :required="true" 
                    />
                    <diyobo-input
                        label="Email" 
                        type="text" 
                        v-model="emailInput"
                        :val="emailInput"
                        :error="emailError"
                        placeholder="Email in buyer's inbox" 
                        :required="true" 
                    />
                    <send-mail-image-uploader />
                    <!-- Start of Who to mail section -->   
                    <section class="who-to-mail">
                        <h2>Who To Email</h2>
                        <diyobo-checkbox
                            label="All Ticket Buyers (306)" 
                            type="check" 
                            checked
                        />
                        <diyobo-checkbox
                            label="GA (306)" 
                            type="check"                                
                        />
                    </section> 
                    <!-- End of Who to mail section --> 
                    <!-- Start of Automated Options section --> 
                    <section>
                        <diyobo-input
                            placeholder="Exhcnage for other event"
                            type="dropdown"
                            :options="automatedOptions"
                            label="Automated Email Options"
                            v-model="automatedSelect"
                            :val="automatedSelect"
                            :required="true"
                            :error="automatedError"
                        /> 
                        <div v-if="automatedSelect == 'xdaysFromEvent'">
                            <diyobo-input
                                label="X days from event" 
                                type="text" 
                                v-model="xdaysInput"
                                :val="xdaysInput"
                                :error="xdaysError"
                                placeholder="Enter number of days from event" 
                                :required="true" 
                            />  
                        </div>
                    </section> 
                    <!-- End of Automated Options section --> 
                </div> 
                <!-- End of Connect Mail column -->  
                <!-- Start of Compose Mail column -->  
                <div class="cust-col quarterx3" id="compose-mail-section">
                    <h2>Your Email</h2>
                    <diyobo-input 
                        label="Subject Line" 
                        type="text"                            
                        placeholder="Name in buyer's inbox" 
                        v-model="subjectInput"
                        :val="subjectInput"
                        :error="subjectError"
                        :required="true" 
                    />
                    <div class="label-title">
                        <label>Email Content*</label>
                    </div>
                    <div id="wysiwyg-wrapper">
                        <wysiwyg 
                            placeholder="Write your message here" 
                            :required="true" 
                            v-model="contentInput"
                            :val="contentInput"
                            :error="contentError"
                        />
                    </div>
                </div>
                <!-- End of Compose Mail column --> 
                <!-- Start of Button column --> 
                <div class="cust-col full" id="comps-buttons-wrapper">
                    <div>
                        <diyobo-button txt="Test" type="secondary" />
                        <diyobo-button v-on:click="validateForm()" txt="Send" type="primary" />
                    </div>
                </div>
                <!-- End of Button column --> 
            </section> 
                <!-- Start of table section --> 
            <section>
                <div class="row">
                    <div class="col full">
                        <div class="table-wrapper">
                            <div class="table-header">
                                <h3>Automated E-Mails</h3>
                            </div>
                            <div class="table-row">
                                <div class="th-element name">Name</div>
                                <div class="th-element mail">Email</div>
                                <div class="th-element subject">Subject</div>
                                <div class="th-element content">Content</div>
                                <div class="th-element who">Who to Mail</div>
                                <div class="th-element auto">Automated Options</div>
                                <div class="th-element edit"></div>
                            </div>
                            <div class="table-row" v-for="mail in automatedList" v-bind:key="mail.id">
                                <div class="td-element name">{{mail.name}}</div>
                                <div class="td-element mail">{{mail.email}}</div>
                                <div class="td-element subject">{{mail.subject}}</div>
                                <div class="td-element content">{{mail.content}}</div>
                                <div class="td-element who">{{mail.whoToMail}}</div>
                                <div class="td-element auto">{{mail.autoptions}}</div>
                                <div class="td-element edit"><button v-on:click="editMail(mail.id)">Edit</button></div>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section>  
            <!-- End of table section -->                   
        </div>
        <!-- End of content-inner --> 
    </div>

</div>

</template>

<style lang="less" scoped>
.content-inner {
  p {
    color: #868686;

    a {
      color: #868686;
    }
  }
  // end of p

  #comps-buttons-wrapper {
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 10px;
      width: 100px;
    }
  }
  // end of comps-buttons-wrapper
  .who-to-mail {
    .input-wrapper {
      label {
        flex-basis: none;
      }
    }
  }
  // end of who-to-mail

  .cust-col {
    margin: 1em;

    &.full {
      width: 100%;
    }
    // end full col

    &.half {
      width: calc(50% - 1em);
    }
    // end half col

    &.third {
      width: calc(33.33% - 1em);
    }
    // end third col

    &.quarter {
      width: calc(23% - 1em);
    }
    // end 1/4 col

    &.thirdx2 {
      width: calc(66.66% - 1em);
    }
    // end 2/3 col

    &.quarterx3 {
      width: calc(72% - 1em);
    }
    // end 3/4 col

    &.eighth {
      width: calc(12.5% - 1em);
    }

    .label-title {
      margin-bottom: 12px;

      label {
      }
    }
  }
  // end of cust-col
  #compose-mail-section {
    &::before {
      content: "";
      position: absolute;
      right: 834px;
      height: 61.5%;
      border-right: 1px solid #5b5b5b;
    }
  }
  .table-wrapper {
    position: relative;
    background-color: #1e1e1e;
    border: solid 1px black;
    border-radius: 8px;
    box-shadow: inset 0 0 5px 2px #000000b9;
    width: 100%;
    overflow: auto;

    .table-header {
      position: absolute;
      left: 10px;
    }
    // end table-header

    .table-row {
      position: relative;
      display: flex;
      //width: 815px;
      padding: 0px 0px 0px 10px;

      .th-element {
        color: #4eb95e;
        font-size: 12px;
        margin: 10px 0px;
        padding: 50px 0px 5px 0px;
        border-bottom: rgba(91, 91, 91, 0.6) solid 1px;
      }
      // end of th-element

      .td-element {
        font-size: 12px;
        color: #999999;
        margin: 5px 0px;
        padding-bottom: 5px;
        border-bottom: rgba(91, 91, 91, 0.3) solid 1px;
        text-align: left;
      }
      // end of td-element

      .icon {
        position: absolute;
        top: 63px;
        width: 10px;
        height: 10px;
        margin-left: 5px;
        color: #999999;
      }
      // end icon

      .name {
        flex-basis: 200px;
      }

      .mail {
        flex-basis: 200px;
      }

      .subject {
        flex-basis: 100px;
      }

      .content {
        flex-basis: 250px;
      }

      .who {
        flex-basis: 200px;
      }

      .auto {
        flex-basis: 150px;
      }

      .edit {
        flex-basis: 55px;
      }
    }
    // end of table-row
  }
  // end table-wrapper
}
// end of content-inner
</style>
<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import wysiwyg from "../components/WYSIWYG.vue";
import SendMailImageUploader from "../components/SendMailImageUploader.vue";

export default {
  name: "automated-email",
  components: {
    DiyoboInput,
    DiyoboButton,
    DiyoboCheckbox,
    wysiwyg,
    SendMailImageUploader
  },
  props: {
    emailError: Boolean,
    nameError: Boolean,
    subjectError: Boolean,
    contentError: Boolean,
    automatedError: Boolean,
    xdaysError: Boolean
  },
  head() {
    return {
      title: "Automated Emails"
    };
  },
  data() {
    return {
      errors: {
        nameError: "",
        mailError: "",
        subjectError: "",
        contentError: "",
        automatedError: "",
        xdaysError: ""
      },
      automatedOptions: [
        { label: "Confirmed ticket purchase", value: "conPurchase" },
        {
          label:
            "Abandoned ticket purchase with account 3 days after abandoned",
          value: "abandonedTicket"
        },
        { label: "X days from event", value: "xdaysFromEvent" },
        { label: "Event date", value: "eventDate" }
      ],
      automatedList: [
        {
          id: 0,
          name: "Henrik Sedin",
          email: "CanucksRock@homtail.ca",
          subject: "Subject",
          content: "Content",
          whoToMail: "All Ticket Buyers",
          autoptions: "Exchange for other event"
        },
        {
          id: 1,
          name: "Henrik Sedin",
          email: "CanucksRock@homtail.ca",
          subject: "Subject",
          content: "Content",
          whoToMail: "All Ticket Buyers",
          autoptions: "Exchange for other event"
        },
        {
          id: 2,
          name: "Henrik Sedin",
          email: "CanucksRock@homtail.ca",
          subject: "Subject",
          content: "Content",
          whoToMail: "All Ticket Buyers",
          autoptions: "Exchange for other event"
        },
        {
          id: 3,
          name: "Henrik Sedin",
          email: "CanucksRock@homtail.ca",
          subject: "Subject",
          content: "Content",
          whoToMail: "All Ticket Buyers",
          autoptions: "Exchange for other event"
        }
      ],
      nameInput: "",
      emailInput: "",
      subjectInput: "",
      contentInput: "",
      errorMessages: [],
      errorsToCorrect: [],
      automatedSelect: "xdaysFromEvent",
      xdaysInput: ""
    };
  },
  methods: {
    validateForm() {
      var self = this;
      this.errorMessages = [];
      this.errorsToCorrect = [];
      if (!self.nameInput) {
        self.errors.nameError = "Name required.";
        self.nameError = true;
      } else if (self.nameInput) {
        self.errors.nameError = null;
        self.nameError = false;
      }

      if (!self.emailInput) {
        self.errors.mailError = "Email required.";
        self.emailError = true;
      } else if (self.emailInput) {
        self.errors.mailError = null;
        self.emailError = false;
      }

      if (!self.subjectInput) {
        self.errors.subjectError = "Email Subject required.";
        self.subjectError = true;
      } else if (self.subjectInput) {
        self.errors.subjectError = null;
        self.subjectError = false;
      }

      if (!self.contentInput) {
        self.errors.contentError = "Email Content required.";
        self.contentError = true;
      } else if (self.contentInput) {
        self.errors.contentError = null;
        self.contentError = false;
      }

      if (!self.automatedSelect) {
        self.errors.automatedError = "Select Automated Mail Options";
        self.automatedError = true;
      } else if (self.automatedSelect) {
        self.errors.automatedError = null;
        self.automatedError = false;
      }

      if (!self.xdaysInput) {
        self.errors.xdaysError = "Number of Days from event";
        self.xdaysError = true;
      } else if (self.xdaysInput) {
        self.errors.xdaysError = null;
        self.xdaysError = false;
      }

      this.errorMessages = Object.values(this.errors);

      this.errorMessages.forEach(function(error) {
        if (error != null) {
          self.errorsToCorrect.push(error);
        }
      });
    },
    editMail(key) {
      this.nameInput = this.automatedList[key].name;
      this.emailInput = this.automatedList[key].email;
      this.contentInput = this.automatedList[key].content;
      this.subjectInput = this.automatedList[key].subject;
    }
  },
  created() {
    this.$store.commit("setTitle", "Automated Emails");
  }
};
</script>
